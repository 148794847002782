.news{
  text-decoration: none;
  color:inherit;
  display: block;

  @media (max-width:$screen-sm)
  {
    max-width:375px;
    margin:0 auto;
  }


  &__data{
    color:$text;
    font-size:1.4em;
    margin:5px 0;
    display: block;

    @media (max-width:$screen-md)
    {
      font-size:1.2em;
    }
  }

  h3 {
    font-size: 1.8em;
    color:$text;
    font-weight: bold;
    margin-bottom:10px;

    @media (max-width:$screen-md)
    {
      font-size:1.6em;
    }

    &:hover{
      color:$mainColor;
    }
    a{
      color:inherit;
      text-decoration: none;
    }
  }

  img{
    &:hover{
      opacity:0.9;
    }
  }

  &__more{
    text-transform: uppercase;
    color:$mainColor;
    font-size:1.4em;
    text-decoration: none;
    font-weight: bold;
    &:hover{
      color: $hoverLink;
    }

  }
}
