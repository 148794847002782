.page{
  height:215px;
  background:url('/media/default/page-banner.jpg')no-repeat;
  background-size:cover;
  position: relative;
  display: flex;
  align-items: center;

  &::before{
    content:'';
    display: block;
    background:rgba(255,255,255,0.9);
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index: 0;
  }

  &__container{
    width:100%;
    @extend .container;
    position: relative;
    z-index: 1;

  }

  h1{
    font-size:3.5em;
    color:$sectionHeader;


    @media (max-width:$screen-md)
    {
      font-size:3em;
    }
  }

  &__header{
    font-size: 2.5em;
    color: #02a5e2;
    font-weight: bold;
  }


}
