.contact{
  padding-top:20px;
  &__row{
    display: flex;
    margin:0 -15px;
    align-items: center;


    @media (max-width:$screen-md)
    {
     flex-direction: column;
    }
  }

  &__col{
    padding:0 15px;
    box-sizing: border-box;

    &:nth-child(1)
    {
      width:40%;

      @media (max-width:$screen-md)
      {
        width:100%;
        padding-bottom:30px;
      }
    }

    &:nth-child(2){
      width:60%;


      @media (max-width:$screen-md)
      {
        width:100%;
      }

    }

  }

  &__map{
    iframe{
      width:100%;
      height:440px;
      border:0;

      @media(max-width:$screen-md)
      {
        height:340px;
      }
    }
  }

  &__text{
    color:$white;
    font-size:1.8em;
    display: flex;
    align-items: center;
    line-height: 160%;
    margin-bottom:15px;
    line-height: 160%;

    &--mt{
      margin-top:40px;
    }

    &--noFlex{
      display: block;
    }



    a{
      text-decoration: none;
      color:inherit;

      &:hover{
        color:$hoverButtonLink;
      }
    }


    i{
      margin-right:5px;
      font-size:18px;
      min-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--large{
      font-size:2.2em;
      margin-bottom:20px;
    }
  }


}
