@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=latin-ext');



  .calendar .day.today, .calendar .event-container .event{
    background-color: #048d48!important;
  } 
  .event-container {
        padding: 10px!important;
    padding-top: 60px!important;
  }
  .callendar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
  }


  
html, body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  &.using-tab {
    a, button {
      &:focus {
        outline: 2px solid $outlineColor;
      }
    }

    *[role="button"] {
      &:focus {
        outline: 2px solid $outlineColor !important;
      }
    }
  }
}

a {
  transition: color 0.3s;
}

p {
  a {
    color: $mainColor;
    text-decoration: none;
  }
}

img {
  display: block;
  max-width: 100%;
}

.html--font-small {
  font-size: .625em
}

.spacing {
  letter-spacing: 2px;
}

.html--font-medium {
  font-size: .7em;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;

  }
}

.html--font-large {
  font-size: 0.8em;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
}


h1, h2, h3, h4, h5, h6 {
  margin: inherit;
  line-height: 140%;
}


.overflow {
  overflow: hidden;
}


.container {
  max-width: 1200px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;


  @media (max-width: $screen-md) {
    max-width: 768px;
  }
}


.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #333;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &__content {
    animation: rotate 1s cubic-bezier(0.1, 0.8, 0.8, 0.1) infinite;
  }

  &__dot {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid #fff;
    display: inline-block;
    margin: 5px;
    background: #fff;
    -webkit-transition: transform 1s;
    -moz-transition: transform 1s;
    -ms-transition: transform 1s;
    -o-transition: transform 1s;
    transition: transform 1s;
    animation: show-hide 1s ease infinite;

    &:nth-child(2) {
      animation-delay: 300ms;
    }

    &:nth-child(3) {
      animation-delay: 600ms;
    }
  }
}

@keyframes show-hide {
  0% {
    transform: scale(0);

  }
  50% {
    transform: scale(1);

  }
  100% {
    transform: scale(0);

  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#preloader.preloader-hiding {
  opacity: 0;
}

#preloader.preloader-hidden {
  display: none;
}


.sl-wrapper .sl-image .sl-caption {
  font-size: 1.6em !important;
}


.scrollTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-size: 50px;
  color: #000;
  z-index: 98;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);

  &.show {
    transform: translateY(0);
  }


  &:hover {
    color: $hoverButtonLink;
  }

}

.iconLink {
  display: block;
  font-size: 2em;
  position: fixed;
  top: 400px;
  text-decoration: none;
  z-index: 10;
  box-sizing: border-box;

  @media (min-width:$screen-md)
  {
    &:hover{
      opacity:0.9;
    }



  }

  @media (max-width:$screen-md)
  {
    transform: scale(0.6);
    top:auto;
    bottom:40px;
  }

  &--diary{
    left:0;

    @media (max-width:$screen-md)
    {


      left: -10px;
    }
  }

  &--facebook{
    right:0;

    @media (max-width:$screen-md)
    {

      right: -10px;
    }
  }
}
