.footer{
  background:$footerBackground;
  padding:20px 0;

  &__container{
    // display: flex;
    // align-items: stretch;
    @extend .container;
  }

  &__row{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin:0 -15px;

    @media (max-width:$screen-sm)
    {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__col{
    width:50%;
    padding:0 15px;
    box-sizing: border-box;
    flex-grow: 1;


    @media (max-width:$screen-sm)
    {
      width:100%;

      &:first-child{
        padding-bottom:15px;
      }
    }
  }

  &__text{
    color:$white;
    font-size:1.2em;
    line-height: 160%;

    &--right{
      text-align: right;

      @media (max-width:$screen-sm)
      {
        text-align: left;
      }
    }

    a{
      color:inherit;
      text-decoration: none;

      &:hover{
        color:$hoverLink;
      }

      img{
        display: inline-block;
        margin-top:10px;
      }
    }

    &--large{
      font-size:1.4em;
      margin-bottom:15px;
    }


  }
}
