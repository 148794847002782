.breadcrumbs {
  padding: 15px 0;

  &__container {
    @extend .container;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;


    li {
      position: relative;
      padding-right: 10px;
      margin-right: 10px;
      font-size: 1.2em;
      color: $text;
      text-decoration: none;

      &::after {
        width: 5px;
        height: 5px;
        border-right: 1px solid $text;
        border-top: 1px solid $text;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        content: '';
        display: block;
      }

      &:last-child {
        &::after {
          display: none;
        }

        font-weight: bold;

      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: $mainColor;
        }
      }

    }
  }
}
