.wcag {
  background: #000000;
  padding: 5px 0;

  @media (max-width:$screen-md)
  {
    padding:8px 0;
  }

  &__container {
    @extend .container;
  }


  &__row {
    display: flex;

    @media (max-width:$screen-sm) {
      justify-content: center;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    margin-right:15px;

    &:last-child{
      margin-right:0;
    }
  }

  &__text {
    color: #fff;
    font-size: 1.1em;
    margin-right:5px;


    @media (max-width:$screen-sm)
    {
      display: none;
    }
  }

  &__link {
    width: 30px;
    height: 30px;
    text-decoration: none;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 2px;
    background:#fff;
    &--black{
      background: #000;
    }

    &--yellow{
      background: #ff0;
    }


  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color:#000;

    &--white{
      color:#fff;
    }

    &--yellow{
      color:#ff0;
    }


    &--small{
      font-size:14px;
    }

    &--medium{
      font-size:17px;
      padding-bottom:2px;
    }

    &--large{
      font-size:20px;
      padding-bottom:4px;
    }

    &--spacing{
      flex-direction: column;
      padding-bottom:5px;

      span{
        line-height: 0;
        font-size:12px;
        margin-top:2px;
      }
    }

  }
}
