$default-color: #d23c1c;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;



$screen-xs:                  480px !default;
$screen-sm:                  767px !default;
$screen-md:                  992px !default;
$screen-lg:                  1199px !default;
$screen-xlg:                  1399px !default;

$white:#fff;
$text:#474747;
$mainColor:#058d48; 
$lightMainColor: #40b93b;
$bannerPagination:#8c8c8c;
$menuLink:#fff;
$hoverLink: #058d48;
$outlineColor: #ffc400;
$menuHoverLink:#ffc400;
$hoverButtonLink:#ffc400;

$section:#fff;
$sectionHeader:#474747;

$galleryOverlay:rgba(64,185,59,0.7);
$contactOverlay:rgba(64,185,59,0.7);

$footerBackground:#474747;

$sidebarHeader:#474747;
$sidebarDivider:#ccc;




