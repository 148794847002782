.search{
  background:rgba(0,0,0,0.75);
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index: 100;
  display: none;


  &__container{
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__form{
    background:$white;
    max-width:600px;
    width:100%;
    margin:0 auto;
    padding:45px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 0px 5px 0 rgba(0,0,0,0.3);



    @media (max-width:$screen-sm)
    {
      padding: 20px 20px;
    }




    form{
      display: flex;
      align-items: flex-end;

      label{
        font-size:2em;
        margin-bottom:5px;
        color:$text;
        display: block;
        @media (max-width:$screen-sm)
        {
          font-size:1.6em;
        }

      }

      input[type="text"]
      {
        font-size:2em;
        border:1px solid #ccc;
        color:$text;
        padding:10px;
        box-sizing: border-box;
        height:53px;
        width: 100%;
        margin-right:10px;

        @media (max-width:$screen-sm)
        {
          height:43px;
        }

        &:focus{
          outline:0;
          border:2px solid $outlineColor;
        }

      }

      button{
        @extend .button;
        font-size:20px;
      }
    }

  }

  &__block{
    width:100%;
  }

  &__close{
    position: absolute;
    top:10px;
    right:10px;
    font-size:30px;
    color:$mainColor;
    outline:none;
    border:0;
    cursor: pointer;
    background:none;
    transition:color 0.3s;


    @media (max-width:$screen-sm)
    {
      top:5px;
      right:5px;
      font-size:25px;
    }


    &:hover{
      color:$hoverLink;
    }
  }
}
