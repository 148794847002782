.menu{
  background:$mainColor;
  z-index: 99;
  position: sticky;
  top:0;


  @media (max-width:$screen-md)
  {
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    overflow-y: auto;
    padding:50px 0;
    display: none;

  }

  &__container{
    @extend .container;
  }

    &__ul{
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      margin:0;
      padding:0;
      margin:0 -5px;

      @media (max-width:$screen-md)
      {
        flex-direction: column;
        justify-content: center;
      }

      li{

        @media (max-width: $screen-md) {
          text-align: center;
          margin-bottom:15px;
        }

        &.active{
          & > a{
            color:$menuHoverLink;
          }
        }

        a{
          padding:15px 10px;
          font-size:1.6em;
          color:$menuLink;
          text-decoration: none;
          transition:color 0.3s;
          font-weight: 600;
          display: flex;
          align-items: center;

          i{
            font-size:0.7em;
            margin-left:5px;
            transition:transform 0.3s;
          }

          @media (max-width: $screen-md) {
            font-size:2em;
            justify-content: center;
            display: inline-flex;
            padding:5px;
          }
        }

        &:hover{
         @media (min-width:$screen-md + 1px)
         {
           & > a{
             background:$lightMainColor;
             i{
               transform:rotate(-180deg);
             }
           }
         }
        }

        &.open{
          & > a{
            background:$lightMainColor;
            @media (max-width:$screen-md)
            {
              background:none;
              color:$menuHoverLink;
            }
            i{
              transform:rotate(-180deg);
            }
          }
        }
      }
    }

  &__dropdown{
    position: relative;

    @media (min-width:$screen-md + 1px) {
      &:hover, &:focus {
        & > ul {
          opacity: 1;
          visibility: visible;
        }
      }
    }

  }

  &__subList
  {

    list-style-type: none;
    padding:0;
    margin:0;
    position: absolute;
    background: $lightMainColor;
    width:200px;
    opacity:0;
    visibility: hidden;
    transition:opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, margin 0.3s ease-in-out;

    &.open{
      @media (min-width:$screen-md + 1px)
      {
        visibility: visible;
        opacity: 1;
      }
    }


    @media (max-width:$screen-md)
    {
      position: relative;
      width: 100%;
      opacity:1;
      visibility: visible;
      background:none;
      display: none;
    }

    li{
      a{
        font-size:1.4em;
        padding-top:10px;
        padding-bottom:10px;


        @media (max-width:$screen-md)
        {
          display: inline-flex;
          font-size:1.8em;
          padding:0;
        }
      }


      @media (max-width:$screen-md)
      {
        margin-top:5px !important;

        &:last-child{
          margin-bottom: 0 !important;
        }
      }


      &:hover{
       & > a{
          color:$menuHoverLink;
        }
      }
    }

  }
}


.menuButton{
  display: none;
  position:fixed;
  top:4px;
  right:5px;
  width:40px;
  height:40px;
  background:$white;
  border:0;
  outline:none;
  z-index: 100;
  transition:top 0.3s ease-in-out;

  @media (max-width:$screen-sm)
  {
    position: absolute;
    top:5px;
  }

  &.fixed{
    position: fixed;
    top:10px;

  }

  &.active{
    position: fixed;
    top:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      position: absolute;

      &:nth-child(1)
      {
        transform:rotate(45deg);
      }

      &:nth-child(3)
      {
        transform:rotate(-45deg);
      }

      &:nth-child(2)
      {
        opacity:0;
      }
    }
  }

  span{
    display: block;
    width:28px;
    height:4px;
    background: $mainColor;
    margin-bottom:5px;
    transition:opacity 0.3s, transform 0.3s;

    &:last-child{
      margin:0;
    }
  }

  @media (max-width:$screen-md)
  {
    display: block;
  }
}
