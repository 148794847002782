.recommended{
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;
  justify-content: center;
  align-items: center;

  &__col{
    padding:0 15px;
    padding-bottom:15px;
    box-sizing: border-box;
    width:25%;


    @media (max-width:$screen-md)
    {
      width:33.33%;
    }

    @media (max-width:$screen-sm)
    {
      width:100%;
    }

    a{
      display: block;
    }

    img{

      margin:0 auto;

      &:hover{
        opacity:0.8;
      }
    }
  }
}
