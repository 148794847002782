.sidebar{
  width:320px;
  padding-left:50px;
  box-sizing: border-box;

  @media (max-width:$screen-md)
  {
    width:100%;
    padding:0;
  }

  &__section{
    padding-bottom:30px;

    &:last-child{
      padding-bottom:0;
    }

    h2{
      margin: 0;
      line-height: 120%;
      padding-bottom: 5px;
      color: $sidebarHeader;
      font-size: 2em;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 3px solid $mainColor;
      margin-bottom: 15px;
    }

    h3{
      font-weight: 400;
      font-size:1.4em;
    }

    ul{
      padding:0;
      margin:0;
      list-style-type: none;

      li{
        padding:5px;
        padding-bottom:10px;

        a{
          color:$text;
          text-decoration: none;
          display: flex;
          align-items: baseline;
          i{
            font-size:16px;
            margin-right:5px;
            color:$mainColor;
            width:20px;
          }

          &:hover{
            color:$hoverLink;
          }
        }
      }
    }
  }

}
