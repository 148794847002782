$colorHeader: $mainColor;
$colorSubHeader: #474747;


.components {
  padding-bottom: 50px;

  @media (max-width: $screen-sm) {
    padding-bottom: 15px;
  }

  &--center {
    text-align: center;
  }

  &__header {
    font-size: 3em;
    color:$mainColor;



    &--center {
      text-align: center;
    }
  }

  &__subHeader {
    font-size: 2em;
    color: $colorSubHeader;

    &--center {
      text-align: center;
    }
  }

  &__row {
    display: flex;
    margin: 0 -15px;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: $screen-sm) {
      flex-direction: column;

      &--gallery{
        flex-direction: row;
      }
    }
  }

  &__col {
    padding: 0 15px;
    padding-bottom: 30px;
    width: 50%;
    box-sizing: border-box;

    @media (max-width: $screen-sm) {
      width: 100%;
      padding-bottom: 15px;
    }
  }

  &__text {
    font-size: 1.8em;
    color: $text;
    line-height: 160%;

    p{
      margin-top:0;
    }
  }

  img {
    display: block;
    margin: 0 auto;
  }

  &__iframe {
    max-width: 670px;
    height: 380px;
    width: 100%;
    margin: 0 auto;
    border: 0;

    @media (max-width: $screen-sm) {
      max-width: 767px;
      height: 50vw;

    }

    &--column {
      max-width: 400px;
      height: 250px;

      @media (max-width: $screen-sm) {
        max-width: 767px;
        height: 50vw;
        width: 100%;

      }
    }
  }

  &__gallery {
    width: 25%;
    padding: 0 15px;
    padding-bottom: 30px;
    box-sizing: border-box;

    @media (max-width: $screen-md) {
      width: 33.33%;
    }

    @media (max-width: $screen-sm) {
      width: 50%;
    }

  }

  a:hover {
    img {
      opacity: 0.8;
    }
  }


}

.formComponent{
  label{
    display: block;
    font-size:1.4em;
    margin-bottom:5px;
    margin-top:20px;
  }
  &__input{
    width:100%;
    box-sizing: border-box;
    font-size:1.6em;
    height:40px;
    padding:5px;
    border:1px solid #ccc;
    outline:none;

    &--textarea{
      resize: none;
      height:200px;
    }
  }

  &__checkbox{
    padding:20px 0;
    display: flex;
    label{
      margin:0;
    }
  }
  &__button{
    background:$mainColor;
    color:$white;
    padding:10px 20px;
    font-weight: bold;
    outline:none;
    font-size:1.6em;
    text-align: center;
    border:0;
    min-width:150px;
    cursor:pointer;
  }
}
