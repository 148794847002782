.header{
  padding:10px 0;
  position: relative;

  &__container{
    @extend .container;
  }

  &__row{
    display: flex;
    flex-direction: row;
    margin:0 -15px;
    justify-content: space-between;
    @media (max-width:$screen-sm)
    {
     flex-wrap: wrap;
    }
  }

  &__col{
    padding:0 15px;
    width:50%;
    box-sizing: border-box;

    @media (max-width:$screen-sm)
    {
      width:100%;
    }
  }

  &__logo{
    max-width:350px;
    font-size:2.35em;
    line-height:120%;
    color:$text;
    display: flex;
    align-items: center;
    text-decoration: none;

    @media (max-width:$screen-md)
    {
      font-size:1.8em;
    }

    @media (max-width:$screen-sm)
    {
      font-size:1.4em;
      padding-right:30px;
    }

    img{
      display: block;
      box-sizing: border-box;
      padding-right:10px;
      // margin-top: 50px; 
      // @media (max-width:$screen-sm)
      // {
      //   margin-top: 10px;
      //   // max-width:70px;
      // }
    }
  }

  &__text{
    color:$text;
    display: flex;
    align-items: center;
    font-size:1.6em;
    justify-content: flex-end;
    line-height: 160%;
    margin-bottom:5px;
    text-align: right;

    @media (max-width:$screen-md)
    {
      font-size:1.4em;
    }

    @media (max-width:$screen-sm)
    {
      display: none;
    }

    a{
      text-decoration: none;
      color:$text;
    }

    i{
      color:$mainColor;
      min-width: 20px;
      display: flex;
      justify-content: center;
      margin-left:5px;
      font-size:18px;
    }

  }

  &__search{
    margin-top:15px;
    font-size:20px;
    display: flex;
    justify-content: flex-end;

    @media (max-width:$screen-sm)
    {
      position: absolute;
      bottom:10px;
      right:15px;
    }

    a{
      color:$mainColor;

      &:hover{
        color:$hoverLink;
      }
    }
  }

}
