.banner{
z-index: 0 !important;



  &__image{
    height:620px;
    width:100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;


    @media (max-width:$screen-md)
    {
      height:420px;
    }

    @media (max-width:$screen-sm)
    {
      height:30em;
    }

  }

  &__blur{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:rgba(255,255,255,0.3);
  }


  &__content{
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    z-index: 1;


  }

  &__container{

    max-width:1000px;
  }

  &__header{
    color:$mainColor;
    font-weight: 600;
    font-size:3.5em;

    @media (max-width:$screen-md)
    {
      font-size:2.5em;
    }


    @media (max-width:$screen-sm)
    {
      font-size:1.4em;
    }
  }

  &__text{
    margin:30px 0;
    color:$text;
    font-size:2em;
    max-width:800px;

    @media (max-width:$screen-md)
    {
      font-size:1.6em;
      margin:20px 0;
    }

    @media (max-width:$screen-sm)
    {
      font-size:1.4em;
      margin:10px 0;
    }

  }

  &__button{
    @extend .button;
  }

  &__pagination{
   @extend .container;
    box-sizing: border-box;
    text-align: left !important;
    right: 0;
    margin:auto;
    bottom:15px !important;

    @media (max-width:$screen-sm)
    {
     bottom:10px !important;
    }

    .swiper-pagination-bullet{
      width:20px !important;
      height:20px !important;
      opacity:1 !important;
      background:$bannerPagination;
      margin-right:8px !important;
      margin-left:0 !important;
      outline:none;

      &-active{
        background:$mainColor;
      }
    }
  }
}
