.gallery{
  display: block;
  position: relative;
  text-decoration: none;

  @media (max-width:$screen-sm)
  {
    max-width:375px;
    margin:0 auto;
  }

  &:hover,&:focus{

  @media (min-width:$screen-md + 1px)
  {
    .gallery{
      &__blur{
        opacity:1;
      }

      &__header{
        opacity:1;
      }

    }

  }

  }

 &__blur{
   content:'';
   display: block;
   position: absolute;
   left:0;
   right:0;
   top:0;
   bottom:0;
   background:$galleryOverlay;
   z-index: 0;
   opacity:0;
   transition:opacity 0.3s;

   @media (max-width:$screen-md)
   {
     display: none;
   }

 }

  img{
    display: block;
  }

  &__header{
    position: absolute;
    z-index: 1;
    color:$white;
    font-size:1.8em;
    left:15px;
    bottom:25px;
    opacity:0;
    transition:opacity 0.3s;
    font-weight: bold;

    @media (max-width:$screen-md)
    {
      opacity:1;
      display: block;
      padding:10px;
      background: $galleryOverlay;
      left:0px;
      right:0px;
      bottom:0px;
      font-size:1.6em;
    }
  }
}
