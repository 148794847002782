.button{
  display:inline-block;
  padding:15px;
  background:$mainColor;
  color:$white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size:1.6em;
  transition:color 0.3s;
  cursor: pointer;
  border:0;
  outline:none;

  @media (max-width:$screen-sm)
  {
    font-size:1.4em;
    padding:10px;
    display: block;
    max-width:355px;
    margin:0 auto;
    text-align: center;
  }



  &:hover{
    color:$hoverButtonLink;
  }
}
