.section {
  background: $section;
  padding: 50px 0;

  &--gallery {

    background-attachment: fixed !important;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.9);
    }
  }

  &--contact {

    background-attachment: fixed !important;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: $contactOverlay;
    }
  }

  &__container {
    @extend .container;
    position: relative;
    z-index: 1;


    &--flex {
      display: flex;
      @media (max-width: $screen-md) {
        flex-direction: column;
      }

    }
  }

  &__header {
    margin: 0;
    line-height: 120%;
    padding-bottom: 5px;
    color: $sectionHeader;
    font-size: 3em;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 3px solid $mainColor;
    margin-bottom: 30px;

    @media (max-width: $screen-sm) {
      text-align: center;
    }

    &--white {
      color: $white;
      border-color: $white;
    }

    &--noBorder {
      padding-bottom: 0;
      border: 0;
      margin-bottom: 15px;
      text-align: left;
    }
  }

  &__row {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;

    &--center {
      justify-content: center;
    }
  }

  &__col {
    padding: 0 15px;
    padding-bottom: 50px;
    box-sizing: border-box;

    &--3 {
      width: 33.33%;

      @media (max-width: $screen-md) {
        width: 50%;
      }

      @media (max-width: $screen-sm) {
        width: 100%;

      }
    }

    &--pb30 {
      padding-bottom: 30px;
    }
  }


  &__button {
    @extend .button;


    &--mt20 {
      margin-top: 20px;
    }
  }


  &__pagination {
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style-type: none;
      margin: 0 -5px;

      li {
        font-size: 1.8em;
        margin: 5px;

        &.disabled {
          display: none;
        }

        a, span {
          text-decoration: none;
          display: block;
          padding: 5px;
          border: 2px solid $mainColor;
          min-width: 20px;
          text-align: center;
          color: $mainColor;

        }

        span {
          background: $mainColor;
          color: $white;
        }


      }
    }
  }

  &__components {

    &--column {
      width: calc(100% - 320px);

      @media (max-width: $screen-md) {
        width: 100%;
      }
    }
  }

  &__list {
    padding-bottom: 30px;
    padding-left: 15px;

    li {
      margin-bottom: 15px;

      a {
        text-decoration: none;
        color: $text;
        font-size: 1.4em;
        line-height: 140%;
        font-weight: 400;

        &:hover {
          color: $hoverLink;
        }
      }
    }

  }

}
